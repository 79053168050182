import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import Navbar from "./components/Navbar";
import background from "./assets/background.svg";
import TermsHeader from "./components/TermHeader";

function App() {
  const pathName = window.location.pathname;

  return (
    //<BrowserRouter>
      <div
        className="w-full h-screen bg-no-repeat bg-center"
        style={{
          backgroundImage: pathName === "/" ? `url(${background})` : "",
          backgroundSize: "cover",
        }}
      >
        {pathName === "/" ? <Navbar /> : <TermsHeader />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </div>
    //</BrowserRouter>
  );
}

export default App;
