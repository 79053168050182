import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import qrCode from "../assets/qr-code.png";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

function Modal({ open, setOpen }: Props) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className="fixed inset-0 z-50 flex items-center justify-center"
    >
      {/* Backdrop */}
      <DialogBackdrop className="fixed inset-0 bg-gray-500/75 transition-opacity ease-in-out duration-300" />

      {/* Modal Panel */}
      <DialogPanel
        className="relative bg-white text-gray-500 flex flex-col items-center justify-center w-[400px] h-[400px] shadow-lg"
        style={{
          borderRadius: 16,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 0,
          padding: 50,
        }}
      >
        {/* Close Button */}
        <button
          onClick={() => setOpen(false)}
          className="absolute top-4 right-4 text-gray-900 text-lg font-bold"
        >
          ×
        </button>

        {/* QR Code */}
        <img
          src={qrCode}
          alt="QR Code"
          className="w-2/3 h-2/3 object-contain mb-4"
        />

        {/* Animated Arrow */}
        <div className="animate-bounce text-gray-900 text-2xl mb-2">↑</div>
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 text-black"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
          style={{
            animation: "bounceSlow 2s infinite",
          }}
        >
          <style>
            {`
      @keyframes bounceSlow {
        0%, 100% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(-10px);
        }
      }
    `}
          </style>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19 9l-7 7-7-7"
          />
        </svg> */}

        {/* Caption */}
        <p className="text-black text-sm font-semibold mt-2">
          Scan to download
        </p>
      </DialogPanel>
    </Dialog>
  );
}

export default Modal;
